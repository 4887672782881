import { useMemo, Fragment } from 'react';
import { Link } from 'react-router-dom';

import {
  Container, Row, Col, Image, Card, Button,
} from 'react-bootstrap';

import { InlineWidget } from 'react-calendly';

import { IconContext } from 'react-icons';
import { BiSolidChevronsRight } from 'react-icons/bi';
import { FaCheckCircle, FaCheck } from 'react-icons/fa';
import { FaGift } from 'react-icons/fa6';
import { MdStarRate, MdStarOutline } from 'react-icons/md';
import { GoPlusCircle } from 'react-icons/go';
import { ImCross } from 'react-icons/im';
import { GiCoffeeCup } from 'react-icons/gi';

import vanessaSmileFingers from '../../assets/img/vanessa-smile-fingers.webp';
import logoMCP from '../../assets/img/logo-mcp-white.webp';
import stampMCP from '../../assets/img/stamp-mcp.webp';
import spinArrowYellow from '../../assets/img/spin-arrow-yellow.webp';
import logoWkCViolet from '../../assets/img/logo-wkc-violet.webp';
import vanessaApp from '../../assets/img/vanessa-app.webp';
import vanessaPins from '../../assets/img/vanessa-pins.webp';
import vanessaPointerFingers from '../../assets/img/vanessa-pointer-fingers.webp';
import vanessaShow from '../../assets/img/vanessa-show.webp';
import phone from '../../assets/img/phone.webp';

import Divider from '../../components/Divider';

import CollapsingMenu from './CollapsingMenu';
import collapsingMenuData from './CollapsingMenu/collapsingMenuData';
import PillarCard from './PillarCard';
import pillarCardData from './PillarCard/pillarCardData';
import EventCard from './EventCard';
import eventCardData from './EventCard/eventCardData';
import CarouselApp from './CarouselApp';
import carouselAppData from './CarouselApp/carouselAppData';
import TeamWkc from '../../components/TeamWkc';
import teamWkcData from '../../data/teamWkcData';
import CarouselTestimonials from '../../components/CarouselTestimonials';
import carouselTestimonialsData from '../../data/carouselTestimonialsData';
import ToggleOffer from './ToggleOffer';
import Faq from './Faq';
import FaqData from './Faq/FaqData';

import {
  workinDiagLink,
  aboutUsLink,
  sellPageHappyCultureLink,
  joinMyCoolPilot,
  freeTrialMyCoolPilot,
  coffeeChatLink,
} from '../../components/Router/routes';

import useBreakpoint from '../../hooks/useBreakpoint';
import useScrollToTop from '../../hooks/useScrollToTop';

import './styles.scss';

const SellPageMyCoolPilot = () => {
  const chevronCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'me-1 text-wkc-darkviolet',
  }), []);
  const checkRoundBlueCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'me-2 text-wkc-blue',
  }), []);
  const checkRoundVioletCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'me-2 text-wkc-violet',
  }), []);
  const giftCSS = useMemo(() => ({
    size: '1.2rem',
    className: 'me-3',
  }), []);
  const starCSS = useMemo(() => ({
    size: '1.5rem',
    className: 'me-2 text-wkc-yellow',
  }), []);
  const plusCSS = useMemo(() => ({
    size: '4rem',
    className: 'my-3',
  }), []);
  const starOutlineCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'me-2 text-wkc-violet',
  }), []);
  const checkBlueCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'me-2 text-wkc-blue',
  }), []);
  const checkDarkVioletCSS = useMemo(() => ({
    size: '1.4rem',
    className: 'me-2 text-wkc-darkviolet',
  }), []);
  const coffeeCSS = useMemo(() => ({
    size: '1.3rem',
    className: 'ms-2',
  }), []);

  const breakpoint = useBreakpoint();
  useScrollToTop();

  return (
    <div className="sellpage-mcp">
      <header className="sellpage-mcp__header bg-wkc-violet">
        <Container>
          <Row>
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 6, order: 1 }}
              className="d-flex flex-column justify-content-end"
            >
              <Image
                className="vanessa"
                src={vanessaSmileFingers}
                alt="Vanessa"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 2 }}
              className="d-flex flex-column justify-content-center align-items-center align-items-md-start"
            >
              <Image
                className="logo-mcp"
                src={logoMCP}
                alt="Logo My Cool'Pilot"
                fluid
                loading="lazy"
                decoding="async"
              />
              <h1 className="title ff--bold text-uppercase text-white">
                <span>Un business</span>
                <br />
                <span>rentable</span>
                <br />
                <span className="lighted--yellow">
                  sereinement
                </span>
                <br />
                <span>et <span className="text-decoration-underline">sans</span></span>
                <br />
                <span>sacrifices !</span>
              </h1>
            </Col>
          </Row>
        </Container>
      </header>
      <section className="section-1 py-5">
        <Container fluid>
          <Row>
            <Col xs={0} md={2} className="section-1__blank" />
            <Col xs={4} md={2} className="section-1__logo bg-wkc-violet">
              <Image
                className="logo-mcp"
                src={logoMCP}
                alt="Logo My Cool'Pilot"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col xs={8} md={4} className="section-1__subtitle bg-wkc-violet">
              <h3>
                Le coaching collectif,<br />
                quotidien et illimité
              </h3>
            </Col>
            <Col xs={0} md={4} className="section-1__empty bg-wkc-violet" />
          </Row>
          <Row className="justify-content-center mt-4">
            <Col xs={12} md={4} className="position-relative">
              <Card className="border--none">
                <Card.Body className="border--rounded bg-wkc-yellow">
                  <ul className="ps-1 mb-0">
                    <li className="d-flex align-items-center">
                      <IconContext.Provider value={chevronCSS}>
                        <BiSolidChevronsRight />
                      </IconContext.Provider>
                      <strong>Plus jamais bloqué</strong>
                    </li>
                    <li className="ms-4">
                      Tu règles tes problématiques dès qu’elles se présentent.
                    </li>
                    <li className="d-flex align-items-center">
                      <IconContext.Provider value={chevronCSS}>
                        <BiSolidChevronsRight />
                      </IconContext.Provider>
                      <strong>Un regard extérieur et des outils concrets</strong>
                    </li>
                    <li className="ms-4">
                      Tu avances plus vite et va plus loin avec l’aide
                      d’experts et de tes pairs : 100% déculpabilisant.
                    </li>
                    <li className="d-flex align-items-center">
                      <IconContext.Provider value={chevronCSS}>
                        <BiSolidChevronsRight />
                      </IconContext.Provider>
                      <strong>Fini la solitude de l’entrepreneur !</strong>
                    </li>
                    <li className="ms-4">
                      Tu profites de la dynamique d’un réseau d’entrepreneur·es
                      qui partagent tes valeurs.
                    </li>
                  </ul>
                </Card.Body>
              </Card>
              <Image
                className="stamp-mcp"
                src={stampMCP}
                alt="Sans deadline"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-2 py-5 position-relative">
        <Container className="mb-5">
          <Row className="justify-content-center mb-4">
            <Col xs={12} md={8}>
              <h2 className="text-uppercase text-center text-md-start">
                Où en es-tu dans ton <span className="lighted--violet">business</span> ?
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={3} className="d-flex flex-column align-items-center align-items-md-end">
              <h4 className="ff--bold text-uppercase text-center text-md-start">
                Test + Debrief <br className="d-none d-md-block" />
                avec un coach <br className="d-none d-md-block" />
                <span className="text-wkc-violet">Pour 0€</span>
              </h4>
              <Image
                className="spin-arrow-yellow"
                src={spinArrowYellow}
                alt="Smin arrow"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col xs={12} md={5}>
              <Card className="border--violet">
                <Card.Body>
                  <Card.Title as="h4" className="ff--bold text-wkc-violet text-center">Ton Master Workin'Diag</Card.Title>
                  <Card.Text>
                    Le check-up complet de&nbsp;
                    <strong>
                      ton mode de fonctionnement
                    </strong>
                    &nbsp;pour identifier :
                  </Card.Text>
                  <Card.Text className="ff--bold d-flex align-items-center justify-content-evenly">
                    <span className="d-flex align-items-center">
                      <IconContext.Provider value={checkRoundBlueCSS}>
                        <FaCheckCircle />
                      </IconContext.Provider>
                      Tes atouts
                    </span>
                    <span className="d-flex align-items-center">
                      <IconContext.Provider value={checkRoundBlueCSS}>
                        <FaCheckCircle />
                      </IconContext.Provider>
                      Tes enjeux
                    </span>
                  </Card.Text>
                  <Card.Text className="d-flex justify-content-center">
                    <IconContext.Provider value={checkRoundVioletCSS}>
                      <FaCheckCircle />
                    </IconContext.Provider>
                    <span>
                      30 min de debrief + conseil<br />
                      avec un coach
                      <span className="ff--bold ms-1 text-uppercase">offert</span>
                    </span>
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-center">
                  <Button variant="wkc-violet">
                    <Link
                      className="d-flex align-items-center text-decoration-none"
                      to={workinDiagLink}
                      target="_blank"
                    >
                      <IconContext.Provider value={giftCSS}>
                        <FaGift />
                      </IconContext.Provider>
                      Je fais le test
                    </Link>
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
        <Divider color="violet" />
      </section>
      <section className="section-3 pt-2 pb-5 bg-wkc-violet">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <h2 className="ff--bold text-center text-white">
                C'est ta boîte qui gère ta vie<br />
                <span className="text-uppercase fs-1 lighted--yellow text-wkc-violet">
                  ou l'inverse ?
                </span>
              </h2>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-4 py-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={1}>
              <h2 className="today mt-3 ff--bold text-wkc-violet text-uppercase">
                Aujourd'hui
              </h2>
            </Col>
            <Col xs={11} md={8}>
              <h4>
                <span className="lighted--violet">Tu as l'impression que :</span>
              </h4>
              <p>
                Tu travailles <strong>dur</strong> pour
                des résultats <strong>mitigés</strong>.
              </p>
              <p>
                Tu <strong>t’éparpilles</strong> à vouloir être partout.
              </p>
              <p>
                Tu te sens <strong>submergé·e</strong> au point
                que <strong>tu passes à côté</strong> de ce qui compte pour toi.
              </p>
              <p>
                Tu vis un <strong>ascenseur émotionnel</strong> permanent qui t’exténue.
              </p>
              <p>
                Tu trouves parfois difficile de
                devoir <strong>tout porter et gérer seul·e</strong>.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center my-5">
            <Col xs={12} md={7} className="d-flex justify-content-center">
              <h4>
                <span className="text-wkc-black lighted--yellow">Ce trop plein génère</span><br />
                <span className="text-wkc-black lighted--yellow"><strong className="text-uppercase ff--bold">beaucoup</strong> de frustration</span>
              </h4>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={10} md={7}>
              <p>
                Ton business "roule", <strong className="text-uppercase">mais</strong> tes objectifs
                pro et perso ne sont <strong>pas vraiment atteints</strong>.
              </p>
              <p>
                Tu te sens tellement <strong>désorganisé·e</strong> que tu ne sais
                plus comment prioriser efficacement.
              </p>
              <p>
                Tu <strong>perds</strong> beaucoup de temps et d’énergie dans
                des actions <strong>qui ne t'apportent rien</strong>.
              </p>
              <p>
                Tu as l'impression de <strong>négliger</strong> tous les autres aspects de ta vie.
              </p>
              <p>
                Tu es <strong>ton pire boss</strong> : tu te mets la pression non-stop.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center my-5">
            <Col xs={11} md={7}>
              <h4 className="ff--bold">
                Tout ça.<br />
                Ce n’est PAS une fatalité !
              </h4>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-5 py-5 bg-wkc-violet">
        <Container>
          <Row>
            <Col xs={12} md={4} className="text-center">
              <Image
                className="logo-mcp"
                src={logoMCP}
                alt="Logo My Cool'Pilot"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col xs={12} md={8} className="d-flex align-items-center">
              <ul className="text-white fs-4 mb-0 ps-0">
                <li className="d-flex align-items-start mb-2">
                  <div>
                    <IconContext.Provider value={starCSS}>
                      <MdStarRate />
                    </IconContext.Provider>
                  </div>
                  Les meilleurs conseils de coachs & experts.
                </li>
                <li className="d-flex align-items-start mb-2">
                  <div>
                    <IconContext.Provider value={starCSS}>
                      <MdStarRate />
                    </IconContext.Provider>
                  </div>
                  JAMAIS seul·e en cas coup dur et moment de doute.
                </li>
                <li className="d-flex align-items-start mb-2">
                  <div>
                    <IconContext.Provider value={starCSS}>
                      <MdStarRate />
                    </IconContext.Provider>
                  </div>
                  Profiter d’une communauté soudée & de l’intelligence collective.
                </li>
                <li className="d-flex align-items-start mb-2">
                  <div>
                    <IconContext.Provider value={starCSS}>
                      <MdStarRate />
                    </IconContext.Provider>
                  </div>
                  Des outils pour développer tes compétences d’entrepreneur.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-6 py-5">
        <Container>
          <Row className="justify-content-center my-5">
            <Col xs={12} md={6}>
              <h3 className="text-uppercase text-wkc-darkviolet ff--bold">
                Imagine, chaque semaine<br />
                Tu es <span className="text-wkc-violet">accompagné·e</span>
              </h3>
            </Col>
          </Row>
          <Row>
            {
              collapsingMenuData.map(({ color, label, content }) => (
                <Col
                  key={label}
                  xs={12}
                  md={4}
                  className="d-flex flex-column my-2"
                >
                  <CollapsingMenu
                    color={color}
                    label={label}
                    content={content}
                  />
                </Col>
              ))
            }
          </Row>
        </Container>
      </section>
      <section className="section-7 py-5 bg-wkc-blue">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <h3 className="text-white">
                Atteindre tous tes objectifs business<br />
                <span className="text-uppercase">et</span><br />
                Prendre soin de toi, en profitant de ta vie,<br />
                <span className="text-uppercase text-wkc-yellow ff--bold">c'est possible !</span>
              </h3>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-8 pt-5 pb-3 pb-md-5">
        <Container fluid>
          <Row>
            <Col xs={12} md={3} className="section-8__logo">
              <Image
                className="logo-mcp"
                src={logoMCP}
                alt="Logo My Cool'Pilot"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col xs={12} md={6} className="section-8__text d-flex justify-content-center flex-column mt-5 mt-md-0">
              <h5 className="mb-3 ms-md-5 text-center text-md-start">
                L’accompagnement repose sur
                les <span className="text-uppercase ff--bold"><br className="d-md-none" />4 piliers essentiels</span> <br />d’un
                business <span className="text-uppercase ff--bold"><br className="d-md-none" />serein, durable et efficace</span>.
              </h5>
              <h5 className="ms-md-5 text-center text-md-start">
                C'est la méthodologie
                <Image
                  className="logo-wkc"
                  src={logoWkCViolet}
                  alt="Logo Wokin'Cool"
                  fluid
                  loading="lazy"
                  decoding="async"
                />
              </h5>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-9 pb-5">
        <Container>
          <Row className="justify-content-evenly">
            {
              pillarCardData.map(({
                number, title, subtitle, text, result, color, picto,
              }) => (
                <Col xs={12} md={6} key={number} className="my-3">
                  <PillarCard
                    key={number}
                    number={number}
                    title={title}
                    subtitle={subtitle}
                    text={text}
                    result={result}
                    color={color}
                    picto={picto}
                  />
                </Col>
              ))
            }
          </Row>
        </Container>
      </section>
      <section className="section-10 py-5">
        <Container fluid>
          <Row>
            <Col xs={12} md={6} className="bg-wkc-yellow mb-3">
              <h2 className="text-end mb-0 py-2">
                C'est <span className="ff--bold lighted--violet">inclus</span> dans ton programme :
              </h2>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-evenly">
            {
              eventCardData.map(({
                picto, mainColor, secondaryColor, frequency, subtitle, content,
              }, index) => (
                <Fragment key={picto}>
                  <Col xs={12} md={6} className="my-5">
                    <EventCard
                      picto={picto}
                      mainColor={mainColor}
                      secondaryColor={secondaryColor}
                      frequency={frequency}
                      subtitle={subtitle}
                      content={content}
                    />
                  </Col>
                  <Col
                    xs={12}
                    className={`text-center ${index === 1 ? 'd-bloc' : 'd-none'}`}
                  >
                    <small>
                      * 1 mois sur deux, nous alternons entre 2 coachings miroir
                      + 1 atelier co-brain ou 1 coaching miroir + 2 ateliers co-brain.
                    </small>
                  </Col>
                  <Col
                    xs={12}
                    className={`text-center ${(index === 1 || index === 3 || index === 5) ? 'd-bloc' : 'd-none'}`}
                  >
                    <IconContext.Provider value={plusCSS}>
                      <GoPlusCircle />
                    </IconContext.Provider>
                  </Col>
                  <Col
                    xs={12}
                    className={`text-center d-md-none ${(index === 0 || index === 2 || index === 4) ? 'd-bloc' : 'd-none'}`}
                  >
                    <IconContext.Provider value={plusCSS}>
                      <GoPlusCircle />
                    </IconContext.Provider>
                  </Col>
                </Fragment>
              ))
            }
          </Row>
        </Container>
      </section>
      <section className="section-11 py-5">
        <Container>
          <Row className="justify-content-center mb-4">
            <Col xs={12} md={6}>
              <h2 className="text-uppercase text-wkc-violet">
                Tout ce qu'il te faut est là !
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <Card className="border--violet bg-wkc-violet">
                <Card.Header className="bg-wkc-violet">
                  <Card.Title className="text-white text-uppercase mb-0">
                    Une méthode simple et redoutablement efficace pour développer ton
                    entreprise sans finir sur les rotules
                  </Card.Title>
                </Card.Header>
                <Card.Body className="bg-white">
                  <Card.Title
                    className="text-wkc-violet text-uppercase ff--bold"
                    as="h6"
                  >
                    2 fois par mois*
                  </Card.Title>
                  <ul className="ps-0">
                    <li className="d-flex align-items-start">
                      <div>
                        <IconContext.Provider value={starOutlineCSS}>
                          <MdStarOutline />
                        </IconContext.Provider>
                      </div>
                      <span>
                        <strong>Coaching miroir</strong> pour prendre du recul,
                        faire sauter des blocages et créer des déclics.
                      </span>
                    </li>
                    <li className="d-flex align-items-start">
                      <div>
                        <IconContext.Provider value={starOutlineCSS}>
                          <MdStarOutline />
                        </IconContext.Provider>
                      </div>
                      <span>
                        <strong>Co-brain</strong> pour partager ses
                        challenges et ses bonnes pratiques.
                      </span>
                    </li>
                  </ul>
                  <Card.Text>
                    <small>
                      * 1 mois sur deux, nous alternons entre 2 coachings miroir
                      + 1 atelier co-brain ou 1 coaching miroir + 2 ateliers co-brain.
                    </small>
                  </Card.Text>
                  <Card.Title
                    className="text-wkc-violet text-uppercase ff--bold"
                    as="h6"
                  >
                    1 fois par mois
                  </Card.Title>
                  <ul className="ps-0">
                    <li className="d-flex align-items-start">
                      <div>
                        <IconContext.Provider value={starOutlineCSS}>
                          <MdStarOutline />
                        </IconContext.Provider>
                      </div>
                      <span>
                        <strong>Masterclass thématique</strong> pour monter en compétences.
                      </span>
                    </li>
                  </ul>
                  <Card.Title
                    className="text-wkc-violet text-uppercase ff--bold"
                    as="h6"
                  >
                    1 fois tous les 2 mois
                  </Card.Title>
                  <ul className="ps-0">
                    <li className="d-flex align-items-start">
                      <div>
                        <IconContext.Provider value={starOutlineCSS}>
                          <MdStarOutline />
                        </IconContext.Provider>
                      </div>
                      <span>
                        <strong>Session de connexion</strong> pour créer un véritable
                        réseau, tisser des liens et trouver des synergies.
                      </span>
                    </li>
                  </ul>
                  <Card.Title
                    className="text-wkc-violet text-uppercase ff--bold"
                    as="h6"
                  >
                    Tout au long du parcours
                  </Card.Title>
                  <ul className="ps-0">
                    <li className="d-flex align-items-start">
                      <div>
                        <IconContext.Provider value={starOutlineCSS}>
                          <MdStarOutline />
                        </IconContext.Provider>
                      </div>
                      <span>
                        <strong>Coaching One-to-one</strong> pour être guidé·e
                        sur tes problématiques de façon personnalisée.
                      </span>
                    </li>
                  </ul>
                  <Card.Title
                    className="text-wkc-violet text-uppercase ff--bold"
                    as="h6"
                  >
                    Illimité
                  </Card.Title>
                  <ul className="ps-0">
                    <li className="d-flex align-items-start">
                      <div>
                        <IconContext.Provider value={starOutlineCSS}>
                          <MdStarOutline />
                        </IconContext.Provider>
                      </div>
                      <span>
                        <strong>Accès à des ressources & outils</strong> pour avancer à ton rythme
                        et faciliter ton quotidien grâce à la MY COOL’APP.
                      </span>
                    </li>
                    <li className="d-flex align-items-start">
                      <div>
                        <IconContext.Provider value={starOutlineCSS}>
                          <MdStarOutline />
                        </IconContext.Provider>
                      </div>
                      <span>
                        <strong>Coaching illimité par message</strong> pour bénéficier de réponses
                        personnalisées à toutes tes questions et avancer
                        en étant toujours bien entouré·e.
                      </span>
                    </li>
                    <li className="d-flex align-items-start">
                      <div>
                        <IconContext.Provider value={starOutlineCSS}>
                          <MdStarOutline />
                        </IconContext.Provider>
                      </div>
                      <span>
                        <strong>Accès au groupe d’échange collectif</strong> pour garder
                        une dynamique de business et d'entraide
                        constante entouré·e d’entrepreneurs de confiance.
                      </span>
                    </li>
                    <li className="d-flex align-items-start">
                      <div>
                        <IconContext.Provider value={starOutlineCSS}>
                          <MdStarOutline />
                        </IconContext.Provider>
                      </div>
                      <span>
                        <strong>Accès aux replays</strong> de tous les lives.
                      </span>
                    </li>
                    <li className="d-flex align-items-start">
                      <div>
                        <IconContext.Provider value={starOutlineCSS}>
                          <MdStarOutline />
                        </IconContext.Provider>
                      </div>
                      <span>
                        <strong>Accès à toutes les mises à jours</strong> et nouvelles ressources
                        de My Cool’Pilot dès leur mise en ligne.
                      </span>
                    </li>
                  </ul>
                </Card.Body>
                <Card.Footer className="bg-white">
                  <Card.Title>
                    <span className="text-uppercase lighted--violet ff--bold me-3">
                      Bonus
                    </span>
                    <span className="text-wkc-violet">
                      Tous les 6 mois, tu bénéficies de 2 séances de
                      coaching individuel déblocables à tout moment.
                    </span>
                  </Card.Title>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col xs={12} md={4} className="text-center">
              <Link
                to={freeTrialMyCoolPilot}
                target="_blank"
              >
                <Button
                  variant="wkc-yellow"
                >
                  <span className="text-uppercase text-wkc-black">Active ton essai offert</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-12 py-5">
        <Container>
          <Row className="justify-content-center mb-4">
            <Col xs={12} md={6}>
              <h3>
                Un contenu de fou dans une appli <strong>pépite</strong> !
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <CarouselApp carouselAppData={carouselAppData} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-13 pt-5">
        <Container>
          <Row className="justify-content-center mb-4">
            <Col xs={12} md={6}>
              <h3>
                Toutes les ressources dans la poche !
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 4, order: 1 }}
              className="text-center"
            >
              <Image
                className="vanessa-app"
                src={vanessaApp}
                alt="My Cool'App"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 1 }}
              className="d-flex flex-column align-items-md-start justify-content-evenly mb-5 mb-md-0"
            >
              <h2 className="fs-1 ff--bold text-uppercase text-wkc-violet">
                My Cool' App
              </h2>
              <h4>
                Pour t'accompagner 24/24h et 7/7j<br />
                <br />
                Les replays de tous les événements.<br />
                Tes ressources, ton agenda & ta messagerie.
              </h4>
              <Link
                to={freeTrialMyCoolPilot}
                target="_blank"
              >
                <Button
                  variant="wkc-violet"
                >
                  <span className="text-uppercase">Active ton essai offert</span>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-14 bg-wkc-violet py-5 position-relative">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <h3 className="text-center text-white pb-3 pb-md-5">
                Quand on entreprend<br />
                <span className="text-uppercase text-wkc-yellow ff--bold">
                  Être libre et heureux·se<br />
                </span>
                ne devrait pas être une option !
              </h3>
            </Col>
          </Row>
        </Container>
        <Divider color="white" />
      </section>
      <section className="section-15 pt-5">
        <Container fluid>
          <Row className="justify-content-center mb-5 ff--bold">
            <Col xs={12} md={3}>
              <h5>
                Je n'ai pas créé
              </h5>
              <h2 className="text-uppercase text-wkc-violet text-center">
                My Cool' Pilot
              </h2>
              <h5 className="text-end">
                par hasard
              </h5>
            </Col>
          </Row>
          <Row>
            <Col xs={0} md={3} />
            <Col xs={12} md={5} className="mb-5">
              <p>
                Moi c’est Vanessa.
                Entrepreneure depuis 2020 et spécialisée dans
                l’accompagnement depuis <strong>+13 ans</strong>.
              </p>
              <p>
                J’ai aidé <strong className="text-wkc-violet">+200</strong> dirigeants et managers à passer
                de <strong>stress intense</strong> à sérénité et plaisir au quotidien.
              </p>
              <p className="mb-1 mt-5">
                Mes journées sont <span className="lighted--blue text-uppercase">bien remplies :</span>
              </p>
              <ul className="ps-0 mb-5">
                <li className="d-flex align-items-start mb-2">
                  <div>
                    <IconContext.Provider value={checkBlueCSS}>
                      <FaCheckCircle />
                    </IconContext.Provider>
                  </div>
                  Maman d’une petite de 3 ans et demi.
                </li>
                <li className="d-flex align-items-start mb-2">
                  <div>
                    <IconContext.Provider value={checkBlueCSS}>
                      <FaCheckCircle />
                    </IconContext.Provider>
                  </div>
                  Aidante ultra-investie pour ma grand-mère.
                </li>
                <li className="d-flex align-items-start mb-2">
                  <div>
                    <IconContext.Provider value={checkBlueCSS}>
                      <FaCheckCircle />
                    </IconContext.Provider>
                  </div>
                  Entrepreneure bouillonnante d’idée.
                </li>
                <li className="d-flex align-items-start mb-2">
                  <div>
                    <IconContext.Provider value={checkBlueCSS}>
                      <FaCheckCircle />
                    </IconContext.Provider>
                  </div>
                  Une communauté de +4000 personnes sur Linkedin,
                  une newsletter et un podcast hebdo.
                </li>
              </ul>
              <p className="mb-5">
                Je réussi à tout mener <strong>sans me cramer</strong> parce
                que j’applique la méthode que je t’apprends dans cet accompagnement.
              </p>
              <div className="text-center text-md-start">
                <Link
                  to={aboutUsLink}
                  target="_blank"
                >
                  <Button variant="wkc-violet">
                    <strong className="text-uppercase">
                      Je t'en dis + sur mon parcours
                    </strong>
                  </Button>
                </Link>
              </div>
            </Col>
            <Col xs={12} md={4} className="px-0 d-flex justify-content-end align-items-end">
              <Image
                className="vanessa-pins"
                src={vanessaPins}
                alt="Vanessa & Pin's"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-16 bg-wkc-yellow py-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <h2>
                Je te propose d'adopter <span className="lighted--violet text-uppercase">ma méthode</span>
              </h2>
              <h4>
                et résoudre toutes tes problématiques
              </h4>
              <h4>
                <strong>au même endroit !</strong>
              </h4>
              <h5>
                <em>Plutôt que de te faire accompagner par 15 personnes en même temps...</em>
              </h5>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-17 py-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <h2 className="text-center mb-5">
                <strong>Mon autre secret ?</strong>
              </h2>
              <h4 className="mb-5">
                <span className="lighted--yellow ff--bold text-uppercase">Une équipe de choc</span> pour construire et animer<br />
                l’accompagnement pour entrepeneur·es<br />
                <span className="text-decoration-underline">ultra-complet !</span>
              </h4>
              <ul className="ps-1 fs-4 mb-5">
                <li className="d-flex align-items-center">
                  <IconContext.Provider value={chevronCSS}>
                    <BiSolidChevronsRight />
                  </IconContext.Provider>
                  Des coachs brillantes et passionnées
                </li>
                <li className="d-flex align-items-center">
                  <IconContext.Provider value={chevronCSS}>
                    <BiSolidChevronsRight />
                  </IconContext.Provider>
                  Un développeur et support IT expert
                </li>
                <li className="d-flex align-items-center">
                  <IconContext.Provider value={chevronCSS}>
                    <BiSolidChevronsRight />
                  </IconContext.Provider>
                  Des experts invités
                </li>
              </ul>
              <h4 className="ff--italic">Et la famille Workin’Cool n’a pas fini de grandir...</h4>
            </Col>
          </Row>
          <TeamWkc teamWkcData={teamWkcData} />
          <Row className="justify-content-center">
            <Col xs={12} md={6} className="text-center">
              <p className="mb-0">
                Prendre soin de toi et de ton business
              </p>
              <p className="ff--bold fs-2 mb-0">
                en continu
              </p>
              <p className="ff--bold fs-4 mb-5">
                ça te dit ?
              </p>
              <Link
                to={joinMyCoolPilot}
                target="_blank"
              >
                <Button variant="wkc-violet">
                  <strong className="text-uppercase">
                    Je rejoins l'aventure
                  </strong>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-18 py-5 bg-wkc-yellow position-relative">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <h3 className="ff--bold text-center text-white pb-3 pb-md-5">
                Ils ont osé sauter le pas pour<br />
                <span className="text-uppercase">entreprendre</span>&nbsp;
                <span className="text-uppercase text-wkc-violet">libres et heureux</span>
              </h3>
            </Col>
          </Row>
        </Container>
        <Divider color="white" />
      </section>
      <section className="section-19 py-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <CarouselTestimonials
                borderColor="violet"
                controlColor="yellow"
                carouselTestimonialsData={carouselTestimonialsData}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-20 py-5 bg-wkc-violet">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <h3 className="ff--bold text-center text-white">
                <span className="text-uppercase lighted--yellow text-wkc-black">Toi aussi</span><br />
                Tu peux créer un business au service de ta vie.
              </h3>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-21 py-5 bg-wkc-blue">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <h3 className="ff--bold text-center text-white">
                <span className="text-uppercase">Pour </span><span className="text-uppercase text-wkc-yellow">passer à l'action</span>
                <br />
                2 possibilités s’offrent à toi :
              </h3>
            </Col>
          </Row>
          <ToggleOffer />
        </Container>
      </section>
      <section className="section-22 pt-5">
        <Container>
          <Row>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
              <Image
                className="vanessa"
                src={vanessaPointerFingers}
                alt="Vanessa"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 2 }}
              className="text-center text-md-start d-flex flex-column justify-content-center ff--bold"
            >
              <h4 className="mb-5">
                Tu n’es pas vraiment sûr·e ?<br />
                Tu as des questions ?
              </h4>
              <h4>
                Réserve <span className="text-uppercase lighted--violet">ton café papote</span><br />
                C’est 100% cool et garanti sans engagement.
              </h4>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col xs={12} className="px-0">
              <InlineWidget
                url="https://calendly.com/vanessa-workincool/rendez-vous-avec-vanessa-workin-cool"
                styles={{
                  height: breakpoint > 768 ? '800px' : '1000px',
                  display: 'block',
                  margin: '0',
                  background: breakpoint > 768 ? '#DD0369' : '#FFFFFF',
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-23 py-5 bg-wkc-blue">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6} className="text-white">
              <h3 className="ff--bold">Psssst !</h3>
              <p>
                Tu rêves d’un accompagnement <strong>individuel</strong> & 100% personnalisé ?
              </p>
              <div className="text-center">
                <Link
                  to={sellPageHappyCultureLink}
                  target="_blank"
                >
                  <Button
                    variant="wkc-yellow"
                  >
                    <strong className="text-uppercase">Je découvre Happy’ Culture</strong>
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-24 py-5">
        <Container>
          <Row className="justify-content-center mb-3">
            <Col xs={12} md={6} className="text-center ff--bold">
              <h3>
                Il faut que je te prévienne
              </h3>
              <h4>
                <span className="lighted--yellow">My Cool’Pilot, n’est pas fait pour tout le monde</span>
              </h4>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <p className="ff--bold mb-2">
                C’est fait pour toi si :
              </p>
              <ul>
                <li className="d-flex align-items-start my-3">
                  <div>
                    <IconContext.Provider value={checkBlueCSS}>
                      <FaCheck />
                    </IconContext.Provider>
                  </div>
                  <span>
                    Tu as déjà les posé les fondations de ton business,
                    tu connais ta cible et tu as une offre que tu vends
                    déjà (même si tu trouves que ce n’est pas assez).
                  </span>
                </li>
                <li className="d-flex align-items-start my-3">
                  <div>
                    <IconContext.Provider value={checkBlueCSS}>
                      <FaCheck />
                    </IconContext.Provider>
                  </div>
                  <span>
                    Tu as envie d'atteindre tes objectifs de manière plus
                    sereine et équilibrée. Ton business, ton rythme, tes règles.
                  </span>
                </li>
                <li className="d-flex align-items-start my-3">
                  <div>
                    <IconContext.Provider value={checkBlueCSS}>
                      <FaCheck />
                    </IconContext.Provider>
                  </div>
                  <span>
                    Tu sens que tu faire encore mieux pour toi et ton business.
                  </span>
                </li>
                <li className="d-flex align-items-start my-3">
                  <div>
                    <IconContext.Provider value={checkBlueCSS}>
                      <FaCheck />
                    </IconContext.Provider>
                  </div>
                  <span>
                    Tu veux t’entourer d’entrepreneur·es bienveillants qui partagent
                    les mêmes valeurs que toi.
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <p className="ff--bold mb-2">
                Ce n’est pas pour toi si :
              </p>
              <ul>
                <li className="d-flex align-items-start my-3">
                  <div>
                    <IconContext.Provider value={checkDarkVioletCSS}>
                      <ImCross />
                    </IconContext.Provider>
                  </div>
                  <span>
                    Tu crois fermement que la seule voie pour réussir
                    c’est d’en baver en permanence et tu es ok avec ça.
                  </span>
                </li>
                <li className="d-flex align-items-start my-3">
                  <div>
                    <IconContext.Provider value={checkDarkVioletCSS}>
                      <ImCross />
                    </IconContext.Provider>
                  </div>
                  <span>
                    Tu n’es pas prêt à t’engager et passer à l’action pour améliorer ton quotidien.
                  </span>
                </li>
                <li className="d-flex align-items-start my-3">
                  <div>
                    <IconContext.Provider value={checkDarkVioletCSS}>
                      <ImCross />
                    </IconContext.Provider>
                  </div>
                  <span>
                    Tu n’as pas envie d’investir du temps et de
                    l’énergie à travailler sur toi et ton business.
                  </span>
                </li>
                <li className="d-flex align-items-start my-3">
                  <div>
                    <IconContext.Provider value={checkDarkVioletCSS}>
                      <ImCross />
                    </IconContext.Provider>
                  </div>
                  <span>
                    Le partage et l’entraide ne t’intéressent pas, tu préfère fonctionner en solo.
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col xs={12} md={6} className="text-center">
              <Link
                to={joinMyCoolPilot}
                target="_blank"
              >
                <Button
                  variant="wkc-violet"
                >
                  <strong className="text-uppercase">Je rejoins l’aventure</strong>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-25 py-5">
        <Container>
          <Row className="justify-content-center mb-4">
            <Col xs={4} md={2} className="text-end">
              <Image
                className="phone"
                src={phone}
                alt="Téléphone"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col xs={8} md={4} className="ff--bold text-uppercase">
              <h3>
                On répond<br />
                <span className="text-wkc-violet">à toutes tes questions</span>
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} md={8} className="d-flex flex-column">
              {
                FaqData.map(({ title, content }) => (
                  <Faq
                    key={title}
                    title={title}
                    content={content}
                  />
                ))
              }
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-26 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col xs={{ span: 12, order: 2 }} md={{ span: 4, order: 1 }}>
              <Image
                className="vanessa"
                src={vanessaShow}
                alt="Vanessa"
                fluid
                loading="lazy"
                decoding="async"
              />
            </Col>
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 4, order: 2 }}
              className="text-center text-md-start d-flex flex-column justify-content-center"
            >
              <h4 className="mb-5 ff--bold">
                L’étape #1 vers un business rentable SEREINEMENT
                et SANS sacrifices ?
              </h4>
              <h4 className="mb-5 ff--bold">
                <span className="text-uppercase lighted--violet">Le café papote</span><br />
                100% cool et 0 engagement !
              </h4>
              <Link
                to={coffeeChatLink}
                target="_blank"
              >
                <Button
                  variant="wkc-violet"
                >
                  <div className="d-flex align-items-center">
                    <strong className="text-uppercase">Je réserve un café papote</strong>
                    <IconContext.Provider value={coffeeCSS}>
                      <GiCoffeeCup />
                    </IconContext.Provider>
                  </div>
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default SellPageMyCoolPilot;
