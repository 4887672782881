// Internal routes
export const aboutUsLink = '/a-propos';
export const meetUsLink = '/nous-rencontrer';
export const deontologyLink = '/deontologie';
export const homeLink = '/';
export const legalNoticesLink = '/mentions-legales';
export const newsletterLink = '/mails-prives';
export const sellPageHappyCultureLink = '/happy-culture';
export const coffeeChatLink = '/cafe-papote';
export const testimonialsLink = '/temoignages';

// MCP routes
export const sellPageMyCoolPilotLink = '/my-cool-pilot';
export const joinMyCoolPilot = '/my-cool-pilot/join';
export const freeTrialMyCoolPilot = '/my-cool-pilot/free-trial';

// Tools routes
export const workinDiagLink = '//tools.workincool.com/workindiag';
export const testSyndromeImposteurLink = '//tools.workincool.com/test-syndromeimposteur';

// Social routes
export const instagramLink = '//www.instagram.com/workincool/';
export const linkedinLink = '//www.linkedin.com/in/vanessarocherieux/';
export const podcastLink = '//smartlink.ausha.co/la-grande-roue';
