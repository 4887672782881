import AboutUs from '../../views/AboutUs';
import MeetUs from '../../views/MeetUs';
import Deontology from '../../views/Deontology';
import Home from '../../views/Home';
import LegalNotices from '../../views/LegalNotices';
import Newsletter from '../../views/Newsletter';
import CoffeeChat from '../../views/CoffeeChat';
import Testimonials from '../../views/Testimonials';

import SellPageHappyCulture from '../../views/SellPageHappyCulture';

// MCP
import SellPageMyCoolPilot from '../../views/SellPageMyCoolPilot';
import JoinMyCoolPilot from '../../views/SubPagesMyCoolPilot/JoinMyCoolPilot';
import FreeTrialMyCoolPilot from '../../views/SubPagesMyCoolPilot/FreeTrialMyCoolPilot';

import {
  aboutUsLink,
  meetUsLink,
  deontologyLink,
  homeLink,
  legalNoticesLink,
  newsletterLink,
  coffeeChatLink,
  sellPageHappyCultureLink,
  sellPageMyCoolPilotLink,
  joinMyCoolPilot,
  freeTrialMyCoolPilot,
  testimonialsLink,
} from './routes';

const linkItems = [
  {
    link: aboutUsLink,
    view: <AboutUs />,
  },
  {
    link: meetUsLink,
    view: <MeetUs />,
  },
  {
    link: deontologyLink,
    view: <Deontology />,
  },
  {
    link: homeLink,
    view: <Home />,
  },
  {
    link: legalNoticesLink,
    view: <LegalNotices />,
  },
  {
    link: newsletterLink,
    view: <Newsletter />,
  },
  {
    link: coffeeChatLink,
    view: <CoffeeChat />,
  },
  {
    link: sellPageHappyCultureLink,
    view: <SellPageHappyCulture />,
  },
  {
    link: sellPageMyCoolPilotLink,
    view: <SellPageMyCoolPilot />,
  },
  {
    link: joinMyCoolPilot,
    view: <JoinMyCoolPilot />,
  },
  {
    link: freeTrialMyCoolPilot,
    view: <FreeTrialMyCoolPilot />,
  },
  {
    link: testimonialsLink,
    view: <Testimonials />,
  },
];

export default linkItems;
